import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className={styles.footer}>
      <p>
        <small>
          Copyright &copy;
          {year}
          . All Rights Reserved.
        </small>
      </p>
    </footer>
  );
};

export default Footer;
