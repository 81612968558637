import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import styles from './Companies.module.scss';

import atlantis from './img/atlantis.png';
import centrichealth from './img/centrichealth.png';
import icondigital from './img/icondigital.png';
import momentum from './img/momentum.png';
import smuckers from './img/smuckers.png';
import toshiba from './img/toshiba.png';

const customStyles = {
  root: {
    color: '#115CA0',
  },
};

export const Companies = (props) => {
  const { classes } = props;
  return (
    <div className={styles.companies}>
      <div className="container">
        <h2>Previous Consultations</h2>
        <ul>
          <li>
            <a href="https://www.entertainmentone.com/" target="_blank" rel="noopener noreferrer">
              <img src={atlantis} alt="Atlantis"/>
            </a>
          </li>
          <li>
            <a href="https://www.centrichealth.ca/" target="_blank" rel="noopener noreferrer">
              <img src={centrichealth} alt="Centric Health"/>
            </a>
          </li>
          <li>
            <a href="https://www.icondigital.com/" target="_blank" rel="noopener noreferrer">
              <img src={icondigital} alt="Icon Digital"/>
            </a>
          </li>
          <li>
            <a href="https://www.momentum.com/" target="_blank" rel="noopener noreferrer">
              <img src={momentum} alt="Momentum"/>
            </a>
          </li>
          <li>
            <a href="https://www.smuckers.ca/" target="_blank" rel="noopener noreferrer">
              <img src={smuckers} alt="Smuckers"/>
            </a>
          </li>
          <li>
            <a href="https://www.toshiba.ca/" target="_blank" rel="noopener noreferrer">
              <img src={toshiba} alt="Toshiba"/>
            </a>
          </li>
        </ul>
        <div className={styles.seeMore}>
          <Button component={NavLink} to="/history" classes={{
            root: classes.root,
          }}>See More Companies I've worked with</Button>
        </div>
      </div>
    </div>
  );
}

Companies.propTypes = {
  classes: PropTypes.shape({}).isRequired,
}

export default withStyles(customStyles)(Companies);
