import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import styles from './Service.module.scss';
import banner from './img/writing.jpg';
import working from './img/working.jpg';
import workingMobile from './img/working-mobile.jpg';
import withPageTemplate from '../../components/withPageTemplate/withPageTemplate';

export const Service = () => (
  <div className={`container ${styles.service}`}>
    <section className={styles.section}>
      <h2 className="section-title">Senior Interim Accounting Management</h2>
      <p>My years of experience and focus allows me to quickly and efficiently grasp the requirements and responsibilities needed to get the job done.</p>
      <ul className={styles.process}>
        <li className={styles.step}>1. Assessment</li>
        <li className={styles.link}>
          <div className={styles.bar} />
        </li>
        <li className={styles.step}>2. Accounting</li>
        <li className={styles.link}>
          <div className={styles.bar} />
        </li>
        <li className={styles.step}>3. Documenting</li>
        <li className={styles.link}>
          <div className={styles.bar} />
        </li>
        <li className={styles.step}>4. Training</li>
      </ul>
    </section>
    <section className={styles.section}>
      <Grid container>
        <Grid item xs={12}>
          <h2 className="section-title">My Services</h2>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ul className={styles.listColumn}>
            <li>Management Accounting Consultation</li>
            <li>Product Costing</li>
            <li>Cost Accounting</li>
            <li>Budgeting</li>
            <li>Balanced Scorecard</li>
            <li>Strategic Planning</li>
            <li>Pricing</li>
            <li>Costing</li>
            <li>Cost Reduction &amp; Process Implementation</li>
            <li>Forecasting (including rolling)</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ul className={styles.listColumn}>
            <li>Setting up Internal Control Systems</li>
            <li>Financial Statement Preparation</li>
            <li>Financial Statment Analysis</li>
            <li>Financial Reporting</li>
            <li>Policy/Procedure Development</li>
            <li>Sarbanes Oxley Documentation</li>
            <li>Year-End (Total Process)</li>
            <li>Establishing Internal Controls</li>
            <li>Policy and Procedure Development</li>
            <li>Staff Training/Mentoring/Appraisal</li>
          </ul>
        </Grid>
      </Grid>
    </section>
    <section className={styles.section}>
      <h2 className="section-title">My Expertise</h2>
      <p>I will back fill any senior accounting individual position quickly.</p>
      <Grid container spacing={16}>
        <Hidden mdUp>
          <Grid item xs={12}>
            <img className={styles.workingImage} src={workingMobile} alt="Two people working in collaboration" />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={7} lg={6}>
          <p>In these positions, I have:</p>
          <ul className={styles.listColumn}>
            <li>maintained/improved cashflow</li>
            <li>managed accounts receivable</li>
            <li>managed accounts payable</li>
            <li>managed payroll</li>
            <li>managed the business</li>
            <li>been the liaison with: banks, lawyers, customers, internal and external auditors, vendors, etc.</li>
            <li>managed the finances</li>
            <li>increased profitability</li>
            <li> prepared and analyzed financial reports with commentary and recommendations for improvements in revenue and expense reduction</li>
            <li>maintained existing internal controls and implemented new controls that are required</li>
            <li>managed staff and provided training</li>
          </ul>
        </Grid>
        <Hidden smDown>
          <Grid item md={5} lg={6}>
            <img className={styles.workingImage} src={working} alt="Two people working in collaboration" />
          </Grid>
        </Hidden>
      </Grid>
      <p>I finish by providing a full set of policies and procedures for the position and an accurate account of any major developments that the returning individual or new hire may require for a successful transition into the role. When needed I have also provided training to new hires for previously vacant accounting positions.</p>
    </section>
  </div>
);

export default withPageTemplate(Service, 'Controllership & Services', banner);
