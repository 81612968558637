import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import styles from './Summary.module.scss';

const customStyles = {
  root: {
    color: '#115CA0',
  },
};

export const Summary = (props) => {
  const { classes } = props;

  return (
    <div className={styles.summary}>
      <div className="container">
        <Grid container spacing={40}>
          <Grid item xs={12} md={6}>
            <div className={styles.icon}>
              <Icon>language</Icon>
            </div>
            <h2>TheGAAP.net</h2>
            <p>TheGAAP.net is the provider of up to date accounting information, trends and changes, serving Accountants and Business people interested in Accounting for their businesses.</p>
            <p>For years, TheGAAP.net has provided Accounting; articles, surveys, employment opportunities, resource recommendations and accounting services by some of the best known speakers, companies and authors.</p>
            <p>Topics include:</p>
            <ul>
              <li>IFRS &amp; other accounting regulatory changes</li>
              <li>Staying updated on Accounting information</li>
              <li>Trends in Accounting</li>
              <li>IFRS &amp; other accounting best practices, and any other business issues affecting Accountants and Business people</li>
            </ul>
            <div className={styles.actions}>
              <Button classes={{
                root: classes.root,
              }} href="https://thegaap.net" target="_blank">Visit TheGAAP.net Now</Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={styles.icon}>
              <Icon>supervised_user_circle</Icon>
            </div>
            <h2>Organizational Involvement</h2>
            <p>I have been involved in many organizations in the past and have been actively providing support whenever I can.</p>
            <p>I currently serve and actively provides support for the following:</p>
            <ul>
              <li><strong>Director</strong>, Toronto East District of the CMA</li>
              <li><strong>Member</strong>, Toastmasters International</li>
            </ul>
            <p>Previously, I have served for or been a member of the following:</p>
            <ul>
              <li><strong>Past Chairperson</strong>, Toronto East District of the CPA</li>
              <li><strong>Past Chairperson</strong>, York Region Chapter of The Society of Management Accountants of Ontario</li>
              <li><strong>Past Director</strong>, York Region Chapter of The Society of Management Accountants of Ontario</li>
              <li><strong>Past Board Member</strong> - evaluating CMA Finalists Presentations</li>
              <li><strong>Member</strong>, Canadian Association of Management Consultants</li>
              <li><strong>Member</strong>, Committee Magic in PJ's for the Children's Wish Foundation</li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(customStyles)(Summary);
