import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import banner from './img/history.jpg';
import styles from './History.module.scss';
import withPageTemplate from '../../components/withPageTemplate/withPageTemplate';

const companies = [
  { href: 'https://www.centrichealth.ca/', label: 'Centric Health' },
  { href: 'https://www.momentum.com/', label: 'Momentum' },
  { href: 'https://www.entertainmentone.com/', label: 'Alliance Atlantis Communications Inc.' },
  { href: 'https://www.toshiba.ca/', label: 'Toshiba Canada Inc.' },
  { href: 'https://www.smuckers.ca/', label: 'The J.M. Smucker Co.' },
  { href: 'https://icondigital.com/', label: 'Icon Digital' },
  { href: 'https://www.morrisonlamothe.com/', label: 'Morrison Lamothe Fine Foods' },
  { href: 'https://www.trimble.com/', label: 'Trimble Navigation' },
  { href: 'https://www.noble.ca/en/', label: 'Noble (Rona)' },
  { href: 'https://www.apotex.com/', label: 'Apotex Inc.' },
  { href: 'https://www.onx.com/', label: 'Onx Enterprise Solution' },
  { href: 'https://www.solvay.com/', label: 'Solvay Pharmaceuticals' },
  { href: 'https://www.alcatel-lucent.com/', label: 'Alcatel Inc.' },
  { href: 'https://www.mylan.ca/', label: 'Mylan Pharmaceuticals ULC (formerly Genpharm Inc.)' },
  { href: 'https://www.merck.com/index.html', label: 'Merck (One of the oldest pharmaceutical companies in the world)' },
  { href: 'https://www.smiths-medical.com/ca-en/', label: 'Smiths Medical Canada Ltd. (Smiths Group)' },
  { href: 'https://www.heartandstroke.com/', label: 'Heart and Stroke Foundation of Ontario' },
  { href: 'https://www.jnjcanada.com/', label: 'Johnson & Johnson' },
  { href: 'https://www.trenchgroup.com/en', label: 'Trench Electric' },
];

export const History = () => (
  <div className={`container ${styles.history}`}>
    <h2 className="section-title">Companies I&apos;ve Helped</h2>
    <p>
      I have benefited many companies with my knowledge and expertise in business accounting and management. If you require an experienced professional to take
      {' '}
      <Link to="/services">controllership</Link>
      {' '}
      of a position in your company, or are in need of
      {' '}
      <Link to="/services">Accounting Management Services</Link>
      , contact me
      {' '}
      <Link to="/contact">here</Link>
!
    </p>
    <Grid container>
      <Grid item xs={12} md={6}>
        <ul className={styles.companies}>
          {companies.slice(0, 10).map(company => (
            <li key={company.label}>
              <a href={company.href} target="_blank" rel="noopener noreferrer">
                <div className={styles.badge}>
                  {company.label.charAt(0)}
                </div>
                <div className={styles.label}>
                  {company.label}
                </div>
              </a>
            </li>
          ))}
        </ul>
      </Grid>
      <Grid item xs={12} md={6}>
        <ul className={styles.companies}>
          {companies.slice(10, 20).map(company => (
            <li key={company.label}>
              <a href={company.href} target="_blank" rel="noopener noreferrer">
                <div className={styles.badge}>
                  {company.label.charAt(0)}
                </div>
                <div className={styles.label}>
                  {company.label}
                </div>
              </a>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  </div>
);

export default withPageTemplate(History, 'History', banner);
