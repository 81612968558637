import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styles from './App.module.scss';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Home from './pages/Home/Home';
import Service from './pages/Service/Service';
import History from './pages/History/History';
import Contact from './pages/Contact/Contact';


const App = () => (
  <div className={`${styles.app} app`}>
    <div className={styles.header}>
      <Header />
    </div>
    <div className={`${styles.content} content`}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services" component={Service} />
        <Route exact path="/history" component={History} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </div>
    <div className="footer">
      <Footer />
    </div>


  </div>
);

export default App;
