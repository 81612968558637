import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from './Home.module.scss';
import banner from './img/banner.jpg';
import About from './components/About/About';
import Summary from './components/Summary/Summary';
import Companies from './components/Companies/Companies';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={styles.home}>
      <div className={styles.banner}>
        <img src={banner} alt="Computer with accounting software" />
        <div className={styles.overlay}>
          <div className={styles.callToActionWrapper}>
            <div className={styles.callToAction}>
              <p>Offering Quality Accounting Consultation Services for Big Businesses.</p>
              <Grid container spacing={8}>
                <Grid item>
                  <Button variant="contained" component={NavLink} to="/history">Learn More</Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" component={NavLink} to="/contact">Get in Touch</Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <section className={styles.section} style={{ background: '#fff' }}>
        <About />
      </section>
      <section className={styles.section}>
        <Summary />
      </section>
      <section className={styles.section} style={{ background: '#fff' }}>
        <Companies />
      </section>
    </div>
  );
};

export default Home;
