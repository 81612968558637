import React from 'react';
import Icon from '@material-ui/core/Icon';

import banner from './img/contact.jpg';
import styles from './Contact.module.scss';
import withPageTemplate from '../../components/withPageTemplate/withPageTemplate';

export const Contact = () => (
  <div className={`container ${styles.contact}`}>
    <h2 className="section-title">Connect With Me</h2>
    <p>
      I am active on social media and manage an influential business and accounting community through
      {' '}
      <a href="https://thegaap.net" rel="noopener noreferrer" target="_blank">TheGAAP.net</a>
      . Please feel free to connect with me on
      {' '}
      <a href="https://www.linkedin.com/in/paul-calleri-cpa-cma-b817583/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
      {' '}
      to receive the latest updates!
    </p>
    <p>For your Senior Interim Accounting Management needs feel free to contact me via the following:</p>
    <ul className={styles.contactInfo}>
      <li>
        <a href="mailto:pcalleri@thegaap.net">
          <div className={styles.icon}>
            <Icon>mail</Icon>
          </div>
          <div className={styles.label}>
            <span>pcalleri@thegaap.net</span>
          </div>
        </a>
      </li>
      <li>
        <a href="tel:+1(416)876-3798">
          <div className={styles.icon}>
            <Icon>phone</Icon>
          </div>
          <div className={styles.label}>
            <span>+1.416.876.3798</span>
          </div>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/paul-calleri-cpa-cma-b817583/" target="_blank" rel="noopener noreferrer">
          <div className={styles.icon}>
            <span className="fab fa-linkedin" style={{ color: '#3176B0' }} />
          </div>
          <div className={styles.label}>
            <span>Connect on Linkedin</span>
          </div>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/thegaapnet" target="_blank" rel="noopener noreferrer">
          <div className={styles.icon}>
            <span className="fab fa-twitter" style={{ color: '#1DA1F2' }} />
          </div>
          <div className={styles.label}>
            <span>Follow on Twitter</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
);

export default withPageTemplate(Contact, 'Contact', banner);
