import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './withPageTemplate.module.scss';

const withPageTemplate = (WrappedComponent, title, img) => class extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className={styles.page}>
        <div className={styles.banner}>
          <img src={img} alt={title} />
          <div className={styles.overlay}>
            <h2>{title}</h2>
          </div>
        </div>
        <div className={`page-container ${styles.content}`}>
          <Card>
            <CardContent>
              <WrappedComponent />
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
};

export default withPageTemplate;
