import React from 'react';
import styles from './ProfileQuote.module.scss';
import profile from './img/profile.jpg';


const ProfileQuote = () => {
  return (
    <div className={styles.profile}>
      <img src={profile} alt="Paul J. Calleri"/>
      <blockquote>
        <p><em>“I am pleased to offer my clients a wide range of high quality accounting consulting services. I have provided these services to some of Canada's largest and best recognized companies.”</em></p>
        <cite>Paul J. Calleri</cite>
      </blockquote>
    </div>
  );
};

export default ProfileQuote;
