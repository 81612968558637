import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import styles from './About.module.scss';
import Button from '@material-ui/core/Button';

import ProfileQuote from './components/ProfileQuote/ProfileQuote';

const customStyles = {
  root: {
    color: '#115CA0',
  },
};

export const About = (props) => {
  const { classes } = props;
  return (
    <div className={styles.about}>
      <div className="container">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={4}>
            <Hidden xsDown>
              <ProfileQuote />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <div className={styles.description}>
              <h2>About Paul J. Calleri.</h2>
              <p>If you've found this website, it is likely you're looking for someone with my expertise. I offer services to companies on a contractual basis matching my wealth of skills and knowledge to ensure success with the most challenging Accounting Projects. My diversified and extensive experience has made me a sought after CPA, CMA consultant who offers interim accounting management for organizations.</p>
              <Hidden smUp>
                <ProfileQuote />
              </Hidden>
              <div className={styles.providedServices}>
                <p>I provide all of the following services:</p>
                <ul>
                  <li>Financial statement preparation/analysis and reporting</li>
                  <li>Product costing</li>
                  <li>Budgeting</li>
                  <li>Forecasting</li>
                  <li>Policy/procedure development</li>
                  <li>Managing/training/mentoring staff</li>
                  <li>Creating and maintaining Internal control systems</li>
                  <li>Sarbanes Oxley and IFRS</li>
                </ul>
              </div>
              <Button classes={{
                root: classes.root,
              }} component={NavLink} to="/services">Learn more about my services</Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

About.propTypes = {
  classes: PropTypes.shape({}).isRequired,
}

export default withStyles(customStyles)(About);
